<template>
  <div id="AccountData">
    <van-nav-bar :fixed="true" z-index="10" placeholder :title="$t('my.transactionDetails')" left-arrow
      @click-left="onClickLeft" class="red-bc white-color">
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('my.transactionDetails') }}</span>
      </template>
    </van-nav-bar>
    <van-tabs v-model="active" @click="handleTabs">
      <van-tab v-for="(item, index) in tabList" :key="index" :title="$t(item.title)">
        <div class="tabs-item">
          <div class="list-item" v-for="(items, index) in newList" :key="index">
            <div class="list-item-left">
              <p class="list-item-title">{{ items.title }}</p>
              <span class="list-item-indate">{{
      dateEvent(items.indate)
    }}</span>
            </div>
            <div class="list-item-right">
              <span v-if="items.setup <= 0" class="list-item-money-green">{{
      items.setup
    }}</span>
              <span v-else class="list-item-money">+{{ items.setup }}</span>
              <!-- language-ch 余额 -->
              <span class="list-item-after">{{ $t('common.balance') }}：{{ items.after }}</span>
            </div>
          </div>
        </div>
      </van-tab>
      <div class="tabs-item"></div>
      <!-- 底部分页的东西 -->
      <div class="bottom-content">
        <div class="Text">
          {{ $t('my.pagingPageSize', {
      total: Paging.Total,
      pageSize: Paging.PageSize,
      num: Paging.Num ? Paging.Num : 1
    }) }}
        </div>
        <van-pagination prev-text="<" next-text=">" v-model="Paging.CurrentPage" mode="simple"
          :total-items="Paging.Total" :items-per-page="Paging.PageSize" @change="PageEvent" />
      </div>
    </van-tabs>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <van-datetime-picker duration="0" v-model="currentDate" confirm-button-text="OK"
        :cancel-button-text="$t('common.cancel')" type="date" title="DatePicker" @confirm="ConfirmEvent"
        @cancel="CancelEvent" :min-date="minDate" :max-date="maxDate" />
    </van-popup>
  </div>
</template>

<script>
import { countTime, getMyTime, deciTurnMoney, formatDate } from '@/utils/tools'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Pagination,
  Toast,
} from 'vant'
import { getAccountData } from '@/api/account'
import { getTimeStamp } from '@/utils/tools'

export default {
  name: 'AccountData',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      show: false,
      TimeOne: '',
      TimeTwo: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 12, 31),
      currentDate: new Date(),
      type: '',
      active: 0,
      tabList: [
        {
          title: 'home.totalTwo',
        },
        {
          title: 'my.income',
        },
        {
          title: 'my.spending',
        },
      ],
      TabelData: [
        {
          money: '100.000',
          Num: 'SY201105021116039',
          Status: 'Pending review',
        },
      ],
      Paging: {
        PageSize: 10,
        CurrentPage: 1,
        Num: '',
        Total: 0,
      },
      list: [],
      userInfo: [],
      pageNo: 1,
      lists: [],
    }
  },
  computed: {
    newList() {
      /**
       * <= 1待审核
       * 2成功
       */
      let list = this.lists.map((_) => ({
        ..._,
        stateTxt: _.status <= 1 ? this.$t('my.pendingReview') : this.$t('my.complete'),
        amount: deciTurnMoney(_.amount),
      }))
      return list
    },
  },
  created() {
    this.init()
    // this.recordAll()
    this.getList()
  },
  methods: {
    dateEvent(indate) {
      return formatDate('Y-m-d H:i:s', indate)
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    //  提交的
    handleSeek() {
      if (this.TimeOne && this.TimeTwo) {
        if (getTimeStamp(this.TimeOne) > getTimeStamp(this.TimeTwo)) {
          Toast(this.$t('bank.failure'))
        } else {
          this.Paging.CurrentPage = 1
          this.list = []
          if (!this.active) {
            this.recordAll()
          } else if (this.active === 1) {
            this.recordWithdrawalList()
          } else {
            this.recordRechargeList()
          }
        }
      } else {
        Toast('No Time')
      }
      // if (!getTimeStamp(this.TimeOne) || !getTimeStamp(this.TimeOne)) {
      //   return false
      // }
    },
    //  日期输入框的点击事件
    DateEvent(num) {
      this.show = true
      if (num === 1) {
        this.type = 1
      } else {
        this.type = 2
      }
    },
    //  日期选择的确定事件
    ConfirmEvent(value) {
      let myDate = `${value.getFullYear()}-${value.getMonth() + 1
        }-${value.getDate()}`
      // let Date = this.module.timestampToTime(this.currentDate.getTime(value))
      if (this.type == 1) {
        this.TimeOne = myDate
      } else {
        this.TimeTwo = myDate
      }
      this.show = false
    },
    //  日期选择的取消事件
    CancelEvent() {
      this.show = false
    },
    // 分页的点击事件
    PageEvent() {
      this.list = []
      // if (!this.active) {
      //   this.recordAll()
      // } else if (this.active === 1) {
      //   this.recordWithdrawalList()
      // } else {
      //   this.recordRechargeList()
      // }
      // 
      this.getList()
    },
    handleTabs(name, title) {
      this.list = []
      this.Paging.CurrentPage = 1
      // if (!name) {
      //   this.recordAll()
      // } else if (name === 1) {
      //   this.recordWithdrawalList()
      // } else {
      //   this.recordRechargeList()
      // }
      this.getList()
    },
    getList() {
      let form = {
        type: 'money',
        setup: this.active,
        page: this.Paging.CurrentPage,
        subtype: '0',
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      })
      getAccountData(form)
        .then((r) => {
          Toast.clear()
          if (r.data.ret === 1) {
            Toast.clear()
            let dataLists = r.data.data.lists
            if (r.data.data.currentPage > 1) {
              for (let i = 0; i < dataLists.length; i++) {
                this.lists.push(dataLists[i])
              }
            } else {
              this.lists = dataLists
            }
          } else {
            Toast.clear()
            this.$toast(r.data.msg)
          }
        })
        .catch((e) => {
          Toast.clear()
          console.error(e)
        })
    },

    /** API **/
    recordAll() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: '201019',
      }
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne)
        form.end_time = getTimeStamp(this.TimeTwo)
      } else {
        delete form.start_time
        delete form.end_time
      }
      getAccountData(form)
        .then((r) => {
          // 
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              // this.list.push(...r.data.notifyList[0].data.data)
              // this.list = r.data.notifyList[0].data.data
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              )
              this.Paging.Total = r.data.notifyList[0].data.count
            } else {
              this.Paging.Num = 0
              this.Paging.Total = 0
              this.list = []
              this.loading = false
            }
          } else {
            this.Paging.Num = 0
            this.Paging.Total = 0

            Toast(this.$t('bank.failure'))
          }
          this.list = r.data.notifyList[0].data.data
        })
        .catch((e) => {

        })
    }, // 总和
    recordWithdrawalList() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: '201016',
      }
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne)
        form.end_time = getTimeStamp(this.TimeTwo)
      } else {
        delete form.start_time
        delete form.end_time
      }
      getAccountData(form)
        .then((r) => {

          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data)
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              )
              this.Paging.Total = r.data.notifyList[0].data.count
            } else {
              this.Paging.Num = 0
              this.Paging.Total = 0
              this.list = []
              this.loading = false
            }
          } else {
            this.Paging.Num = 0
            this.Paging.Total = 0
            this.list = []
            this.loading = false
            Toast(this.$t('bank.failure'))
          }
          this.list = r.data.notifyList[0].data.data
        })
        .catch((e) => {

        })
    }, // 提现记录
    recordRechargeList() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: '201018',
      }
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne)
        form.end_time = getTimeStamp(this.TimeTwo)
      } else {
        delete form.start_time
        delete form.end_time
      }
      getAccountData(form)
        .then((r) => {

          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data)
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              )
              this.Paging.Total = r.data.notifyList[0].data.count
            } else {
              this.Paging.Num = 0
              this.Paging.Total = 0
              this.list = []
              this.loading = false
            }
          } else {
            this.Paging.Num = 0
            this.Paging.Total = 0
            this.list = []
            this.loading = false
            Toast(this.$t('bank.failure'))
          }
        })
        .catch((e) => {

        })
    }, // 充值记录
    /** 其他 **/
    init() {
      this.userInfo = localStorage.getItem('userInfo')
    },
    isPositiveNumber(num) {
      if (num) {
      }
      if (typeof num === 'number') {
        if (num) {
          return true
        } else {
          return false
        }
      } else {
      }
    },
  },
}
</script>

<style lang="less">
#AccountData {
  width: 100%;
  height: 100%;

  .tabs-item {
    width: 100%;

    >.item-content {
      width: calc(100% - 1.25rem);
      border-bottom: 1px solid #ccc;
      padding: 0.625rem;

      >div {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 400;

        >span {
          color: red;
        }
      }

      >div:nth-child(1) {
        font-size: 0.875rem;
      }
    }
  }

  .van-nav-bar {
    background-color: transparent;
    .van-icon{
      color: #fff;
    }
  }

  .van-tabs {
    .van-tabs__wrap {
      border-bottom: 1px solid #eee;
    }
  }

  .bottom-content {
    width: 100%;

    >.Text {
      width: 100%;
      text-align: center;
      line-height: 1.25rem;
      color: #666;
      font-size: 20px;
    }
  }

  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0;

    .Text {
      margin: 0 0.625rem;
      font-size: 0.875rem;
    }

    >.button-Time {
      display: block;
      text-align: center;
      width: 6.5rem;
      height: 1.875rem;
      line-height: 1.875rem;
      border-radius: 0.9375rem;
      font-size: 0.875rem;
      background: #f2f2f2;
    }

    >.van-button {
      height: 1.875rem;
      width: 5rem;
      border-radius: 0.9375rem;
      color: #fff;
      background: #ff7614;
    }

    // 标签栏的
  }
}

.list-item {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: calc(1rem / 16);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  box-sizing: border-box;
}

.list-item-left {
  flex: 1;
}

.list-item-right {
  display: flex;
  align-items: flex-end;
}

.list-item-title {
  flex: 1;
  font-size: calc(6rem / 16);
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* 作为弹性伸缩盒子模型显示。 */
  -webkit-box-orient: vertical;
  /* 设置伸缩盒子的子元素排列方式--从上到下垂直排列 */
  -webkit-line-clamp: 1;
  /* 显示的行 */
}

.list-item-indate {
  margin-top: 6px;
  font-size: 20px;
  color: #888888;
}

.list-item-money,
.list-item-money-green {
  padding-left: 6px;
  font-size: 20px;
  color: #dc1323;
}

.list-item-money-green {
  color: #1bb019;
}

.list-item-after {
  margin-top: calc(3rem / 16);
  font-size: 20px;
  line-height: 17px;
  padding-left: 6px;
  color: #555555;
}
</style>